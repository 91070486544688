import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { createRef, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useDoc } from "../../../hooks/useDoc";
import {
  BrainChaseRoom,
  BrainChaseSet,
} from "../admin/AdminBrainChaseRoomManager";
import { BrainChaseWrapper } from "../BrainChaseWrapper";
import background from "../image/red_black_bg.png";

export const BrainChaseGameReRun: React.FC = () => {
  const { roomId } = useParams<{ roomId: string; hash: string }>();
  const [room] = useDoc<BrainChaseRoom>({
    path: "brainchase/" + roomId,
  });

  const [roomSet] = useDoc<BrainChaseSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const [currentProblem, setCurrentProblem] = useState(0);

  const [startAt, setStartAt] = useState<Date>();
  const [lastSolvedAt, setLastSolvedAt] = useState<Date>();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [userClass, setUserClass] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const [answerMessage, setAnswerMessage] = useState<string>("");

  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = createRef<HTMLInputElement>();
  const [isComposition, setIsComposition] = useState(false);

  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === " ") {
      setInputValue("");
    } else {
      setInputValue(value);
    }
  };

  useEffect(() => {
    if (answerMessage !== "") {
      setTimeout(() => {
        setAnswerMessage("");
      }, 2000);
    }
  }, [answerMessage]);

  const submit = () => {
    if (inputValue === "") return;

    if (
      !roomSet?.problems[currentProblem].answer.split(",").includes(inputValue)
    ) {
      setAnswerMessage("不正解です！");
      setInputValue("");

      return;
    }
    setAnswerMessage("正解です！");
    setInputValue("");
    setCurrentProblem((prev) => prev + 1);
    setLastSolvedAt(new Date());
    inputRef.current?.focus();
  };

  const countDown =
    !room || !startAt || !currentTime || !roomSet
      ? defaultOffsetTime
      : defaultOffsetTime -
        (currentTime.getTime() - startAt?.getTime()) / 1000 +
        parseInt(roomSet?.classes[userClass].time);
  const remainingTime =
    !room || !startAt || !currentTime
      ? 600
      : Math.max(
          parseInt(roomSet?.config?.timeLimit ?? " 600") +
            parseInt(roomSet?.classes[roomSet.classes.length - 1].time ?? "0") -
            (currentTime.getTime() - startAt?.getTime()) / 1000 +
            defaultOffsetTime,
          0
        );

  const maxProblem = roomSet?.problems.length ?? 15;

  const thinkingTime = useMemo(() => {
    if (!lastSolvedAt) return 0;
    if (currentProblem === 0) {
      return (
        (currentTime.getTime() - lastSolvedAt.getTime()) / 1000 -
        defaultOffsetTime -
        parseInt(roomSet?.classes[userClass].time ?? "0")
      );
    }
    return (currentTime.getTime() - lastSolvedAt.getTime()) / 1000;
  }, [currentTime, lastSolvedAt, currentProblem, roomSet]);

  const isMobile = useMediaQuery("(max-width: 500px)");

  const replaceList = useMemo(() => {
    const clearTimeMs =
      lastSolvedAt && startAt
        ? lastSolvedAt!.getTime() -
          startAt!.getTime() -
          parseInt(roomSet?.classes[userClass].time ?? "0") * 1000
        : 0;
    return [
      ["@rank@", "---位"],
      [
        "@clearTime@",
        `${("0" + Math.floor(clearTimeMs / 60000)).slice(-2)}:${(
          "0" + Math.floor((clearTimeMs / 1000) % 60)
        ).slice(-2)}`,
      ],
      ["@finishedProblemNumber@", (currentProblem + 1).toString()],
      ["@title@", roomSet?.config?.title ?? ""],
      ["@setNumber@", roomSet?.config?.setNumber ?? ""],
      ["@streamUrl@", roomSet?.config?.streamUrl ?? ""],
    ];
  }, [roomSet, userClass, lastSolvedAt, startAt]);
  const getTwitterUrl = (text: string) => {
    replaceList.forEach(([key, value]) => {
      console.log(text, key, value);
      text = text.replaceAll(key, value.toString());
    });
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
  };

  if (!roomSet || !room) {
    return <></>;
  }
  if (room.mode !== "end") {
    return (
      <BrainChaseWrapper>
        <Bg>
          <Box sx={{ minHeight: "100vh" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "md",
                minHeight: "100vh",
                verticalAlign: "middle",
                margin: "auto",
              }}
            >
              <Typography variant="h5" sx={{ mt: 2 }}>
                追走機能は
                <br />
                ブレインチェイス本編終了後に
                <br />
                ご利用いただけます。
              </Typography>
            </Stack>
          </Box>
        </Bg>
      </BrainChaseWrapper>
    );
  }

  return (
    <BrainChaseWrapper>
      <Bg>
        <Box sx={{ minHeight: "100vh" }}>
          {countDown > 0 && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: '"Bebas Neue", sans-serif',
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontSize: "20vw",
                  background: "linear-gradient(#fff,#ccc)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  mb: 8,
                }}
              >
                BRAIN CHASE
              </Typography>
              <Box sx={{ my: 2 }} />
              {roomSet?.config?.title && (
                <>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                    }}
                  >
                    種目名
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                    }}
                  >
                    {roomSet?.config?.title ?? ""}
                  </Typography>
                </>
              )}
              <Box sx={{ my: 1 }} />
              <p>競技ルールやハンデは動画をご覧ください。</p>
              <Box sx={{ my: 1 }} />
              {!startAt && (
                <>
                  {roomSet.classes.map((c, i) => (
                    <>
                      <Box sx={{ my: 1 }} />
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          setStartAt(new Date());
                          setLastSolvedAt(new Date());
                          setUserClass(i);
                          setCurrentProblem(0);
                        }}
                      >
                        {c.class}としてスタート
                      </Button>
                    </>
                  ))}
                  <Box sx={{ my: 2 }} />
                </>
              )}{" "}
              {startAt && countDown > 0 && (
                <>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                    }}
                  >
                    スタートまで
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontSize: "6rem",
                    }}
                  >
                    {Math.floor(Math.ceil(countDown) / 60)}:
                    {("" + (Math.ceil(countDown) % 60)).padStart(2, "0")}
                  </Typography>
                </>
              )}
            </Stack>
          )}
          {startAt &&
            countDown <= 0 &&
            currentProblem < maxProblem &&
            remainingTime > 0 && (
              <>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100vw",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2rem",
                    }}
                  >
                    終了まで {Math.floor(Math.ceil(remainingTime) / 60)}:
                    {("" + (Math.ceil(remainingTime) % 60)).padStart(2, "0")}
                  </Typography>
                  <Box sx={{ height: 10 }} />
                  <div
                    className="question"
                    style={{
                      width: "100%",
                      border: "none",
                      maxWidth: "100%",
                      margin: "0 auto",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={roomSet?.problems[currentProblem].image ?? ""}
                      alt="問題画像"
                      style={{
                        margin: 0,
                        opacity: 0.9,
                        border: "2vw solid #d41b05",
                      }}
                    />
                  </div>
                  {answerMessage === "" ? (
                    <Box sx={{ height: 48 }} />
                  ) : (
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 2,
                      }}
                    >
                      {answerMessage}
                    </Typography>
                  )}
                  {
                    //description
                    roomSet?.problems[currentProblem].description && (
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 1,
                        }}
                      >
                        解答形式:{" "}
                        {roomSet?.problems[currentProblem].description}
                      </Typography>
                    )
                  }
                  <Stack direction={isMobile ? "column" : "row"} spacing={4}>
                    <input
                      type="text"
                      value={inputValue}
                      ref={inputRef}
                      onChange={handleInputChange}
                      className="answer"
                      onCompositionStart={() => setIsComposition(true)}
                      onCompositionEnd={() => setIsComposition(false)}
                      onKeyDown={(e) => {
                        if (isComposition) return;
                        if (e.key === "Enter") {
                          submit();
                        }
                      }}
                      autoFocus
                    />{" "}
                    <div style={{ textAlign: "center" }}>
                      <Button
                        size="large"
                        onClick={submit}
                        variant={"contained"}
                      >
                        送信
                      </Button>
                    </div>
                  </Stack>
                  {
                    // hints
                    roomSet?.problems[currentProblem].hints &&
                      (
                        roomSet?.problems[currentProblem].hints as {
                          text: string;
                          time: string;
                        }[]
                      ).map((hint, i) => {
                        return thinkingTime > parseInt(hint.time) ? (
                          <Box sx={{ mx: 2 }}>
                            <Typography
                              key={i}
                              variant="h6"
                              sx={{
                                mt: 2,
                              }}
                            >
                              ヒント{i + 1}: {hint.text}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ mx: 2 }}>
                            <Typography
                              key={i}
                              variant="h6"
                              sx={{
                                mt: 2,
                              }}
                            >
                              ヒント{i + 1}:{" "}
                              {parseInt(hint.time) - Math.floor(thinkingTime)}
                              秒後に公開されます。
                            </Typography>
                          </Box>
                        );
                      })
                  }
                  <Box sx={{ height: 10, margin: "auto" }} />
                </Stack>
              </>
            )}
          {(remainingTime <= 0 || currentProblem === maxProblem) && (
            <>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100vw",
                }}
              >
                <Typography variant="h1" sx={{ mt: 2 }}>
                  Result
                </Typography>
                <Box />
                <Typography variant="h3" sx={{ mt: 2 }}>
                  クラス: {roomSet?.classes[userClass].class ?? ""}
                </Typography>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  (+{roomSet?.classes[userClass].time}秒)
                </Typography>
                <Typography variant="h3" sx={{ mt: 2 }}>
                  成績:{" "}
                  {currentProblem === maxProblem
                    ? `${
                        ((lastSolvedAt!.getTime() -
                          startAt!.getTime() -
                          parseInt(roomSet?.classes[userClass].time ?? "0") *
                            1000) /
                          60000) |
                        0
                      }分${(
                        "" +
                        (Math.floor(
                          (lastSolvedAt!.getTime() -
                            startAt!.getTime() -
                            parseInt(roomSet?.classes[userClass].time ?? "0") *
                              1000) /
                            1000
                        ) %
                          60)
                      ).padStart(2, "0")}秒${
                        Math.floor(
                          (lastSolvedAt!.getTime() -
                            startAt!.getTime() -
                            parseInt(roomSet?.classes[userClass].time ?? "0") *
                              1000) /
                            100
                        ) % 10
                      }
                でクリア！`
                    : `Q${currentProblem + 1}で終了`}
                </Typography>

                <>
                  <Button
                    onClick={() => {
                      const url = `${getTwitterUrl(
                        currentProblem === maxProblem
                          ? roomSet.config.tweetTextClearForRerun ??
                              roomSet.config.tweetText ??
                              ""
                          : roomSet.config.tweetTextNotClearForRerun ??
                              roomSet.config.tweetText ??
                              ""
                      )}`;
                      window.open(url, "_blank");
                    }}
                    variant={"contained"}
                    size={"large"}
                    sx={{ mx: "auto", my: 5 }}
                  >
                    Twitterでシェア
                  </Button>
                </>
              </Stack>
            </>
          )}
        </Box>
      </Bg>
    </BrainChaseWrapper>
  );
};

const Bg = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position: bottom;
  font-family: "Noto Sans JP", sans-serif;
`;
