import {
  doc,
  onSnapshot,
  setDoc,
  SetOptions,
  updateDoc,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { db } from "..";

type DocArg<T> = {
  path?: string | null;
  initialData?: T;
};

type Option = {
  updateData: (data: any) => void;
};

export const useDoc = <T,>({
  path,
  initialData,
}: DocArg<T>): [
  T | undefined,
  (data: T, option?: SetOptions) => void,
  boolean,
  Option
] => {
  const [data, setLocalData] = useState<T>(initialData as T);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!db) {
      return;
    }
    if (!path) {
      return;
    }
    const unsub = onSnapshot(doc(db, path), (docSnapshot) => {
      if (!docSnapshot.exists()) {
        if (initialData) {
          setDoc(doc(db, path), initialData);
        }
        setLoading(false);
        return;
      }
      setLoading(false);
      setLocalData({ ...docSnapshot.data() } as T);
    });
    return unsub;
  }, [db, path]);

  const setData = useCallback(
    (data: T, option?: SetOptions) => {
      if (!db) {
        return;
      }
      if (!path) {
        return;
      }
      setDoc(doc(db, path), data as any, option ?? {});
    },
    [db, path]
  );

  const updateData = useCallback(
    (data: any) => {
      if (!db) {
        return;
      }
      if (!path) {
        return;
      }
      updateDoc(doc(db, path), data);
    },
    [db, path]
  );

  return [data, setData, loading, { updateData }];
};
